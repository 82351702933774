import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Settings from './setting/reducers';
import Adsets from './adset/reducers';
import Tracks from './track/reducers';
import Accounts from './account/reducers';
import Customers from './customer/reducers'
import ThirdpartyApps from './thirdpartyapp/reducers';
import Forms from './forms/reducers'
import FormSchemas from './formSchema/reducers'
import Orders from './orders/reducers'
import Products from './product/reducers'
import Users from './user/reducers'
import Enterprises from './enterprise/reducers'
import ExtOrders from './extOrders/reducers'
import Services from './service/reducers'
import WxCustomers from './wxCustomer/reducers'
import AuthorizedEnterprises from './authorizedEnterprise/reducers'
import CustomerAcquisitions from './customerAcquisitions/reducers'
import YianOrders from './yianOrders/reducers'
import WxUsers from './wxUsers/reducers'


export default combineReducers({
    Auth,
    Layout,
    Settings,
    Adsets,
    Tracks,
    Customers,
    Accounts,
    ThirdpartyApps,
    Forms,
    FormSchemas,
    Orders,
    Products,
    Users,
    Enterprises,
    ExtOrders,
    Services,
    WxCustomers,
    AuthorizedEnterprises,
    CustomerAcquisitions,
    YianOrders,
    WxUsers
});
