import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listWxUsers as listWxUsersApi,
} from 'helpers';
import { wxUserApiResponseSuccess, wxUserApiResponseError, WxUser } from './actions';
import { WxUserActionTypes } from './constants';

type WxUserPayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: WxUser[] | WxUser | {};
    page?: number,
    pageSize?: number,
    corp_id: string,
    search?: string
  }
  type: string
}

function* listWxUsers({ payload: { corp_id, page = 1, pageSize, search }, type }: WxUserPayload): SagaIterator {
  try {
    const response = yield call(listWxUsersApi, corp_id, page, pageSize, search);
    const { wxUsers } = response;
    yield put(wxUserApiResponseSuccess(WxUserActionTypes.LIST_WXUSERS, wxUsers))
  } catch (error: any) {
    yield put(wxUserApiResponseError(WxUserActionTypes.LIST_WXUSERS, error));
  }
}

function* watchListWxUsers() {
  yield takeEvery(WxUserActionTypes.LIST_WXUSERS, listWxUsers);
}

function* wxUserSaga() {
  yield all([fork(watchListWxUsers)])
}

export default wxUserSaga;