import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  listCustomerAcquisitions as listCustomerAcquisitionsApi,
  getCustomerAcquistion as getCustomerAcquistionApi,
  getAuthCorp as getAuthCorpApi
} from 'helpers';
import { customerAcquisitionApiResponseSuccess, customerAcquisitionApiResponseError, CustomerAcquisition } from './actions';
import { CustomerAcquisitionsActionTypes } from './constants';

type CustomerAcquisitionPayload = {
  payload: {
    id?: number,
    enterpriseId?: number
    data: CustomerAcquisition[] | CustomerAcquisition | {};
    page?: number,
    pageSize?: number,
    corp_id: string
    limit: number
    linkId: string
  }
  type: string
}

function* listCustomerAcquisitions({ payload: { corp_id }, type }: CustomerAcquisitionPayload): SagaIterator {
  try {
    const response = yield call(listCustomerAcquisitionsApi, corp_id);
    const { customerAcquisitions } = response;
    yield put(customerAcquisitionApiResponseSuccess(CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS, customerAcquisitions))
  } catch (error: any) {
    yield put(customerAcquisitionApiResponseError(CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS, error));
  }
}

function* getCustomerAcquisition({ payload: { linkId, corp_id }, type }: CustomerAcquisitionPayload): SagaIterator {
  try {
    const response = yield call(getCustomerAcquistionApi, linkId, corp_id);
    const { customerAcquisition } = response;
    yield put(customerAcquisitionApiResponseSuccess(CustomerAcquisitionsActionTypes.GET_CUSTOMERACQUISITION, customerAcquisition))
  } catch (error: any) {
    yield put(customerAcquisitionApiResponseError(CustomerAcquisitionsActionTypes.GET_CUSTOMERACQUISITION, error));
  }
}

function* getAuthCorp({ payload: { corp_id }, type }: CustomerAcquisitionPayload): SagaIterator {
  try {
    const response = yield call(getAuthCorpApi, corp_id);
    const { authCorp } = response;
    yield put(customerAcquisitionApiResponseSuccess(CustomerAcquisitionsActionTypes.GET_AUTH_CORP, authCorp))
  } catch (error: any) {
    yield put(customerAcquisitionApiResponseError(CustomerAcquisitionsActionTypes.GET_AUTH_CORP, error));
  }
}

function* watchListCustomerAcquisitions() {
  yield takeEvery(CustomerAcquisitionsActionTypes.LIST_CUSTOMERACQUISITIONS, listCustomerAcquisitions);
}

function* watchGetCustomerAcquisition() {
  yield takeEvery(CustomerAcquisitionsActionTypes.GET_CUSTOMERACQUISITION, getCustomerAcquisition);
}

function* customerAcquisitionsSaga() {
  yield all([fork(watchListCustomerAcquisitions), fork(watchGetCustomerAcquisition), fork(watchGetAuthCorp)])
}

function* watchGetAuthCorp() {
  yield takeEvery(CustomerAcquisitionsActionTypes.GET_AUTH_CORP, getAuthCorp);
}

export default customerAcquisitionsSaga;